<app-header2></app-header2>
<!--====== Breadcrumb part Start ======-->
    <section class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/aboutus2.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">Qui somme nous</h1>
                <ul>
                    <li><a routerLink='/'>Acceuil</a></li>
                    <li>A propos de nous</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section>
    <!--====== Breadcrumb part End ======-->

    <!--====== About Section start ======-->
    <section class="about-section about-illustration-img section-gap">
        <div class="container">
        
            <div class="row no-gutters justify-content-lg-end justify-content-center">
                <div class="col-lg-12 col-md-12">
                    <div class="about-text">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag">Qui somme nous ?</span>
                            <h2 class="title">Bienvenue chez KOUBANCA Digital</h2>
                        </div>
                        <p class="mb-25">
                            Nous sommes une équipe passionnée de développeurs et de créateurs,
                             dédiée à transformer vos idées en applications innovantes tout en offrant 
                             un rapport qualité-prix exceptionnel. Chez KOUBANCA Digital, nous 
                             croyons que des solutions technologiques de haute qualité ne doivent pas nécessairement 
                             coûter une fortune. C’est pourquoi nous nous engageons à
                             rendre le développement d’applications accessible à tous, sans compromettre la qualité.<br>
                             Notre mission  :
                        </p>
                    
                        <ul class="about-list">
                            <li> <i class="fas fa-check"></i> <b>Accessibilité</b> : Nous nous efforçons de rendre les technologies de pointe accessibles à un large éventail de clients,
                                 des startups aux entreprises établies, en offrant des services à des prix compétitifs</li>
                            <li> <i class="fas fa-check"></i><b>Qualité abordable</b> : Nos solutions sont conçues pour répondre à vos besoins spécifiques tout en respectant des standards de qualité élevés, sans vous alourdir avec des coûts excessifs</li>
                            <li> <i class="fas fa-check"></i><b> Innovation </b>: Nous utilisons les dernières technologies et méthodologies pour créer des applications qui non seulement répondent à vos attentes, mais les dépassent, tout en restant dans votre budget</li>
                        
                        </ul>
                    
                    </div>
                </div>
            </div>
        </div>
    </section>
<hr>
    <!--====== Service Section End ======-->

    <!--====== Advanced Tabs Section Start ======-->
    <section class="advanced-tab section-gap">
        <div class="container">
            <!-- Tabs Buttons -->
            <div class="tab-buttons">
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a class="active" id="nav-mission" data-toggle="tab" href="#tab-mission" role="tab">Notre Mission &
                        Vision</a>
                    <a id="nav-history" data-toggle="tab" href="#tab-history" role="tab"> technologies utilisées</a>
                
                </div>
                <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="tab-mission" role="tabpanel">
                        <div class="tab-text-block left-image with-left-circle">
                            <div class="row align-items-center justify-content-center">
                                <div class="col-lg-6 col-md-10">
                                    <div class="block-image">
                                        <img src="assets/img/vision.jpg" alt="Image">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-10">
                                    <div class="block-text">
                                        <h2 class="title">Excellence à chaque étape, réussite à chaque projet</h2>
                                        <p>
                                            Nous visons à être le leader mondial dans l’innovation technologique, 
                                            en créant des solutions qui transforment la vie quotidienne et favorisent un avenir durable
                                        </p>
                                        <ul>
                                            <li>
                                                <i class="fas fa-check"></i>
                                                Notre mission est de développer des logiciels de pointe qui simplifient les processus d’affaires et augmentent la productivité, en mettant l’accent sur la qualité, l’innovation et la satisfaction client
                                            </li>
                                            <li>
                                                <i class="fas fa-check"></i>
                                                Nous valorisons l’intégrité, l’innovation, et la collaboration, en nous efforçant de maintenir les plus hauts standards éthiques dans tous nos projets.
                                            </li>
                                            <li>
                                                <i class="fas fa-check"></i>
                                                Nous nous engageons à offrir une expérience client exceptionnelle, en fournissant des solutions sur mesure qui répondent précisément aux besoins uniques de chaque client
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="tab-history" role="tabpanel">
                        <div class="tab-text-block right-image with-right-circle">
                            <div class="row align-items-center justify-content-center">
                                <div class="col-lg-6 col-md-10 order-2 order-lg-1">
                                    <div class="block-text">
                                        <h2 class="title">Langage de programmation utilisé</h2>
                                        <p>
                                            Nous employons les langages de programmation les plus avancés et les plus utilisés pour transformer vos idées en solutions numériques concrètes
                                        </p>
                                        <ul>
                                            <li>
                                                <i class="fas fa-check"></i>
                                               Java (Spring Boot)
                                            </li>
                                            <li>
                                                <i class="fas fa-check"></i>
                                                javascript,TypeScript (Angular, React JS,Laravel)
                                            </li>
                                            <li>
                                                <i class="fas fa-check"></i>
                                                PHP (Laravel,symfony)
                                            </li>
                                            <li>
                                                <i class="fas fa-check"></i>
                                              Dart (Flutter)
                                            </li>
                                            <li>
                                                <i class="fas fa-check"></i>
                                              Python 
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-10 order-1 order-lg-2">
                                    <div class="block-image">
                                        <img src="assets/img/teck.jpg" alt="Image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                              
                </div>
            </div>
        </div>
    </section>
  
    <!--====== Client Area End ======-->
    <app-footer2></app-footer2>