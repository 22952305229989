<app-header2></app-header2>
<!--====== Breadcrumb part Start ======-->
    <section class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/appel.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">Contactez-nous</h1>
                <ul>
                    <li><a routerLink='/'>Acceuil</a></li>
                    <li>Contact</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section>
    <!--====== Breadcrumb part End ======-->

    <!--====== Contact Section start ======-->
    <section class="contact-section contact-page section-gap-top">
        <div class="container">
            <div class="contact-info">
                <div class="row justify-content-center">
                    <div class="col-lg-6 order-2 order-lg-1">
                        <div class="illustration-img text-center">
                            <img src="assets/img/appelD.jpg" alt="Image" height="500" width="800">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-10 order-1 order-lg-2">
                        <div class="contact-info-content">
                            <div class="section-title left-border mb-40">
                                <span class="title-tag">Appelez nous directemennt</span>
                                <h2 class="title">Nous vous répondrons rapidement et avec attention</h2>
                            </div>
                            <p>
                                Faites le premier pas vers le succès – nous sommes impatients de collaborer avec vous !
                            </p>

                            <ul>
                                <li class="phone">
                                    <a href="tel:+0123456789"><i class="far fa-phone"></i>+226 60 01 99 92</a>
                                </li>
                                <li><i class="far fa-envelope-open"></i><a routerLink='/contact-us'>koubancadigital@gmail.com</a></li>
                                <li><i class="far fa-map-marker-alt"></i>Ouagadougou ,BURKINA FASO</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="contact-form grey-bg">
                <div class="row no-gutters justify-content-center">
                    <div class="col-10">
                        <div class="section-title text-center mb-40">
                            <h2 class="title">N'hésitez pas à nous soummetre votre projet</h2>
                        </div>

                        <form action="#">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="input-group mb-30">
                                        <input type="text" placeholder="Votre nom complet">
                                        <span class="icon"><i class="far fa-user-circle"></i></span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="input-group mb-30">
                                        <input type="email" placeholder="Votre adresse email">
                                        <span class="icon"><i class="far fa-envelope-open"></i></span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="input-group mb-30">
                                        <input type="text" placeholder="Votre numéro de téléphone">
                                        <span class="icon"><i class="far fa-phone"></i></span>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input-group textarea mb-30">
                                        <textarea placeholder="Votre message"></textarea>
                                        <span class="icon"><i class="far fa-pencil"></i></span>
                                    </div>
                                </div>
                                <div class="col-12 text-center">
                                    <button type="button" class="main-btn">Envoyer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Contact Section start ======-->
    <!--====== Client Area End ======-->
    <app-footer2></app-footer2>