<footer>
    <div class="container">
        <div class="footer-widget">
            <div class="row">
                
                <div class="col-lg-4 col-sm-5 order-1">
                    <div class="widget site-info-widget">
                        <div class="footer-logo">
                            <img src="assets/img/LogoK.png" alt="Finsa"  height="120">
                        </div>
                        <p>Ensemble changeons le monde</p>
                        <ul class="social-links">
                            <li><a href="javascript:void(0)"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="javascript:void(0)"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="javascript:void(0)"><i class="fab fa-instagram"></i></a></li>
                            <li><a href="javascript:void(0)"><i class="fab fa-behance"></i></a></li>
                            <li><a href="javascript:void(0)"><i class="fab fa-dribbble"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 order-3">
                    <div class="widget nav-widget">
                        <h4 class="widget-title">Liens court</h4>
                        <ul>
                            <li><a href="javascript:void(0)">Acceuil</a></li>
                            <li><a href="javascript:void(0)">Tout savoir sur nous</a></li>
                            <li><a href="javascript:void(0)">Nos Services</a></li>
                            <li><a href="javascript:void(0)">Contact</a></li>
                    
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5 order-lg-4 order-5">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="widget nav-widget">
                                <h4 class="widget-title">Liens d'aide</h4>
                                <ul>
                                    <li><a href="javascript:void(0)">Politiquess & confidentialités</a></li>
                                    <li><a href="javascript:void(0)">Mentions légales</a></li>
                                    <li><a href="javascript:void(0)">Besoin d'aide</a></li>
                                
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="widget contact-widget">
                                <h4 class="widget-title">Contact </h4>
                                <p>Contactez-nous </p>
                                <ul class="contact-infos">
                                    <li>
                                        <a href="tel:+0123456789">
                                            <i class="far fa-phone"></i>
                                            +226 60 01 99 92 / 70 75 77 25
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:koubancadigital@gmail.com">
                                            <i class="far fa-envelope-open"></i>
                                            koubancadigital@gmail.com
                                        </a>
                                    </li>
                                    <li> <i class="far fa-map-marker-alt"></i> Ouagadougou ,BURKINA FASO</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-copyright">
            <p class="copyright-text">
                <span>copyright@ <a href="javascript:void(0)">KOUBANCA Digital</a>.  -2024</span>
                <span>Tout droits réservés</span>
            </p>

            <a href="javascript:void(0)" class="back-to-top"><i class="far fa-angle-up"></i></a>
        </div>
    </div>

    <!-- Lines -->
    <img src="assets/img/lines/01.png" alt="line-shape" class="line-one">
    <img src="assets/img/lines/02.png" alt="line-shape" class="line-two">
</footer>