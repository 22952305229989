<header class="header-two sticky-header">
        <!-- Header Topbar -->
        <div class="header-topbar">
            <div class="container-fluid container-1600">
                <div class="header-topbar-inner d-md-flex align-items-center justify-content-between">
                    <!-- Contact Info -->
                    <ul class="contact-info">
                        <li>
                            <a href="javascript:void(0)"><i class="far fa-phone"></i>+266 60 01 99 92</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"><i class="far fa-envelope-open"></i>koubancadigital@gmail.com</a>
                        </li>
                        <li>
                            <i class="far fa-map-marker-alt"></i>Ouagadougou BURKINA FASO
                        </li>
                    </ul>
                    <!-- Social Links -->
                    <ul class="social-links">
                        <li>
                            <a href="javascript:void(0)"><i class="fab fa-facebook-f"></i></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"><i class="fab fa-twitter"></i></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"><i class="fab fa-instagram"></i></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"><i class="fab fa-behance"></i></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"><i class="fab fa-dribbble"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- Header Menu  -->
        <div class="header-nav sticky-nav">
            <div class="container-fluid container-1600">
                <div class="nav-container">
                    <!-- Site Logo -->
                    <div class="site-logo">
                        <a routerLink='/'><img src="assets/img/LogoK.png" alt="Logo" width="200"  height="120"></a>
                    </div>

                    <!-- Main Menu -->
                    <div class="nav-menu d-lg-flex align-items-center">

                        <!-- Navbar Close Icon -->
                        <div class="navbar-close">
                            <div class="cross-wrap"><span></span><span></span></div>
                        </div>

                        <!-- Mneu Items -->
                        <div class="menu-items">
                            <ul>
                             <li>  <a routerLink='/' routerLinkActive="active">Acceuil</a></li>
                    
                        
                             <li><a routerLink='/services' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Nos services </a></li>
                             <li><a routerLink='/a_propos' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Tout savoir sur nous </a></li>
                                <li><a routerLink='/contact-nous' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                            </ul>
                        </div>
                        <!-- Pushed Item -->
                        <div class="nav-pushed-item"></div>
                    </div>

                    <!-- Navbar Extra  -->
                    <div class="navbar-extra d-flex align-items-center">
                    
                        <!-- off canvas menu toggler-->
                        <div class="offcanvas-toggler">
                            <span><span></span><span></span><span></span></span>
                        </div>
                        <!-- Navbar Toggler -->
                        <div class="navbar-toggler">
                            <span></span><span></span><span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <!--====== OFF CANVAS START ======-->
    <div class="offcanvas-wrapper">
        <div class="offcanvas-overly"></div>
        <div class="offcanvas-widget">
            <a href="javascript:void(0)" class="offcanvas-close"><i class="fal fa-times"></i></a>
            <!-- About Widget -->
            <div class="widget about-widget">
                <div class="logo mb-30">
                    <img src="assets/img/LogoK.png" alt="Image" width="146" height="146">
                </div>
                <p>
                    Nous sommes une équipe passionnée de développeurs et de créateurs,
                    dédiée à transformer vos idées en applications innovantes tout en offrant 
                    un rapport qualité-prix exceptionnel.
                </p>
            </div>
            <!-- Contact information Widget -->
            <div class="widget contact-info-widget">
                <h5 class="widget-title">Contactez-nous</h5>
                <ul>
                   
                    <li>
                        <i class="far fa-phone"></i>
                        +226 60 01 99 92
                    </li>
                    <li>
                        <i class="far fa-envelope-open"></i>
                        koubancadigital@example.com
                    </li>
                </ul>
            </div>
         
           
            <div class="widget social-link">
                <h5 class="widget-title">Nous suivre sur </h5>
                <ul>
                    <li><a href="javascript:void(0)"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="javascript:void(0)"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="javascript:void(0)"><i class="fab fa-linkedin"></i></a></li>
                
                </ul>
            </div>
        </div>
    </div>
    <!--====== OFF CANVAS END ======-->