<app-header2></app-header2>
<!--====== Banner part start ======-->
    <section class="banner-section banner-section-two">
        <div class="banner-slider" id="bannerSlider">
            <div class="single-banner" style="background-image: url(assets/img/banner/header1.jpg);">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="banner-content text-center">
                                <h1 data-animation="fadeInDown" data-delay="0.8s">Bénéficiez de l’expertise
                                    du leader   <br> 
                                    </h1>
                                <p data-animation="fadeInUp" data-delay="1s">
                                    Prêt à transformer vos idées en réalité ? Contactez KOUBANCA Digital aujourd'hui pour une consultation gratuite et découvrez comment nous pouvons vous aider à atteindre vos objectifs
                                </p>
                                <ul class="btn-wrap">
                                    <li data-animation="fadeInLeft" data-delay="1.2s">
                                        <a routerLink='/contact-nous' class="main-btn main-btn-4">Nous conntacter </a>
                                    </li>
                                    <li data-animation="fadeInRight" data-delay="1.4s">
                                        <a routerLink='/services' class="main-btn main-btn-2">Nos Services</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="single-banner" style="background-image: url(assets/img/banner/header2.jpg);">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="banner-content text-center">
                                <h1 data-animation="fadeInDown" data-delay="0.8s">La digitalisation à la portée de tous <br></h1>
                                <p data-animation="fadeInUp" data-delay="1s">
                                    Vous cherchez une équipe de développement d'applications capable de transformer vos idées en solutions innovantes et efficaces
                                </p>
                                <ul class="btn-wrap">
                                    <li data-animation="fadeInLeft" data-delay="1.2s">
                                        <a routerLink='/contact-nous' class="main-btn main-btn-4">Nous conntacter </a>
                                    </li>
                                    <li data-animation="fadeInRight" data-delay="1.4s">
                                        <a routerLink='/services' class="main-btn main-btn-2">Nos Services</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="single-banner" style="background-image: url(assets/img/banner/header3.jpg);">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="banner-content text-center">
                                <h1 data-animation="fadeInDown" data-delay="0.8s">Réinventez vos idées  <br></h1>
                                <p data-animation="fadeInUp" data-delay="1s">
                                    Notre mission est de vous fournir des solutions de développement d'applications qui allient innovation, performance et excellence.
                                </p>
                                <ul class="btn-wrap">
                                    <li data-animation="fadeInLeft" data-delay="1.2s">
                                        <a routerLink='/contact-nous' class="main-btn main-btn-4">Nous conntacter </a>
                                    </li>
                                    <li data-animation="fadeInRight" data-delay="1.4s">
                                        <a routerLink='/services' class="main-btn main-btn-2">Nos Services</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Banner part end ======-->

    <!--====== About Part start ======-->
    <section class="about-section section-gap">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <div class="about-video">
                        <div class="video-poster-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms"
                            style="background-image: url(assets/img/aboutus2.jpg);">
                        </div>
                        <div class="video-poster-two wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms"
                            style="background-image: url(assets/img/aboutus1.jpg);">
                        
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-10">
                    <div class="about-text-two">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag">Qui sommes nous ?</span>
                            <h2 class="title">Bienvenue chez KOUBANCA Digital </h2>
                        </div>

                        <p>
                            Nous sommes une équipe passionnée de développeurs et de créateurs,
                             dédiée à transformer vos idées en applications innovantes tout en offrant 
                             un rapport qualité-prix exceptionnel. Chez KOUBANCA Digital, nous 
                             croyons que des solutions technologiques de haute qualité ne doivent pas nécessairement 
                             coûter une fortune. C’est pourquoi nous nous engageons à
                             rendre le développement d’applications accessible à tous, sans compromettre la qualité.<br>
                             Notre mission  :
                        </p>

                        <ul class="about-list">
                            <li> <i class="fas fa-check"></i> <b>Accessibilité</b> : Nous nous efforçons de rendre les technologies de pointe accessibles à un large éventail de clients,
                                 des startups aux entreprises établies, en offrant des services à des prix compétitifs</li>
                            <li> <i class="fas fa-check"></i><b>Qualité abordable</b> : Nos solutions sont conçues pour répondre à vos besoins spécifiques tout en respectant des standards de qualité élevés, sans vous alourdir avec des coûts excessifs</li>
                            <li> <i class="fas fa-check"></i><b> Innovation </b>: Nous utilisons les dernières technologies et méthodologies pour créer des applications qui non seulement répondent à vos attentes, mais les dépassent, tout en restant dans votre budget</li>
                        
                        </ul>
                        <a routerLink='/a_propos' class="main-btn">Lire plus</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== About Part end ======-->

    <!--====== Service Part Start ======-->
    <section class="service-section shape-style-two service-line-shape section-gap grey-bg">
        <div class="container">
            <!-- Section Title -->
            <div class="section-title text-center both-border mb-50">
                <span class="title-tag">Nos services</span>
                <h2 class="title">Voici ce que nous proposons </h2>
            </div>
            <!-- Services Boxes -->
            <div class="row service-boxes justify-content-center">
                <div class="col-lg-6 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                    <div class="service-box-two text-center">
                        <div class="icon">
                            <i class="flaticon-chart"></i>
                        </div>
                        <h3><a routerLink='/'>Développement d'applications web(site web ..)</a></h3>
                        <a routerLink='/' class="service-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                    <div class="service-box-two text-center">
                        <div class="icon">
                            <i class="flaticon-chart"></i>
                        </div>
                        <h3><a routerLink='/'>Administrations Réseaux</a></h3>
                        <a routerLink='/' class="service-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                    <div class="service-box-two text-center">
                        <div class="icon">
                          <i class="flaticon-chart"></i>
                        </div>
                        <h3><a routerLink='/'>Développement d'applications mobile(Android,IOS)</a></h3>
                        <a routerLink='/' class="service-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="500ms">
                    <div class="service-box-two text-center">
                        <div class="icon">
                            <i class="flaticon-diagram"></i>
                        </div>
                        <h3><a routerLink='/'>UX/UI Design (Charte graphique ,Confection des afffiches publicitaire) </a></h3>
                        <a routerLink='/' class="service-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
             
            
            </div>
        </div>
        <div class="line-one">
            <img src="assets/img/lines/12.png" alt="line-shape">
        </div>
        <div class="line-two">
            <img src="assets/img/lines/11.png" alt="line-shape">
        </div>
    </section>
    <!--====== Service Part End ======-->

    <!--====== Project section Start ======-->
    <section class="project-section">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-8">
                    <!-- Section Title -->
                    <div class="section-title left-border">
                        <span class="title-tag">Nos projets</span>
                        <h2 class="title">Nos projet récemment réalisés</h2>
                    </div>
                </div>
            </div>

            <!-- Project Boxes -->
            <div class="row project-boxes mt-80 justify-content-center">
                <div class="col-lg-4 col-sm-6">
                    <div class="project-box">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/projet1.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/'>Site Web</a></h4>
                            <p>Site web de l'entreprise GEI</p>
                            <a routerLink='/' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="project-box">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/projet1.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/'>Site web </a></h4>
                            <p>Site web de l'entreprise qualiApproche</p>
                            <a routerLink='/' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="project-box">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/projet2.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/'>Fonsecure</a></h4>
                            <p>Pour la suivi des parcelles</p>
                            <a routerLink='/' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            
                   
            </div>
        </div>
    </section>
    <!--====== Project section End ======-->

    <!--====== Fact Part Start ======-->
    <section class="fact-section-two">
        <div class="container">
            
        </div>
    </section>
    <!--====== Fact Part End ======-->

    <!--====== Working Process Part Start ======-->
    <section class="working-process-section grey-bg">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-10 order-lg-1 order-2">
                    <div class="process-text">
                        <!-- Section Title -->
                        <div class="section-title left-border mb-30">
                            <span class="title-tag">Nos approches et Méthodologies</span>
                            <h2 class="title">Nous travaillons en  AGILE/SCRUM</h2>
                        </div>
                        <p>
                            Chez KOUBANCA Digital, nous croyons fermement que le succès d’un projet repose sur une collaboration étroite avec nos clients. Nous mettons en place un processus collaboratif qui vise à garantir que chaque aspect du projet est aligné avec vos attentes et objectifs. Voici comment nous procédons.
                        </p>
                        <!-- process-loop -->
                        <div class="process-loop">
                            <div class="single-process wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                                <div class="icon">
                                    <i class="fal fa-coffee"></i>
                                    <span>01</span>
                                </div>
                                <div class="content">
                                    <h4>Analyse Préliminaire et Découverte</h4>
                                    <p>Il s'agira de recueillir des informations détaillées pour orienter la conception et la planification</p>
                                </div>
                            </div>
                            <div class="single-process wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                <div class="icon">
                                    <i class="fal fa-coffee"></i>
                                    <span>02</span>
                                </div>
                                <div class="content">
                                    <h4>Co-création de la Vision du Projet</h4>
                                    <p>Il s'agira  d'aligner les attentes des parties prenantes et à définir une direction claire pour le projet</p>
                                </div>
                            </div>
                            <div class="single-process wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="800ms">
                                <div class="icon">
                                    <i class="fal fa-coffee"></i>
                                    <span>03</span>
                                </div>
                                <div class="content">
                                    <h4>Développement Agile et Feedback Continus</h4>
                                    <p>Cela nous permettra de créer des solutions plus adaptées aux besoins des utilisateurs tout en optimisant les processus de développement</p>
                                </div>
                            </div>
                            <div class="single-process wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="800ms">
                                <div class="icon">
                                    <i class="fal fa-coffee"></i>
                                    <span>04</span>
                                </div>
                                <div class="content">
                                    <h4>Tests et Validation</h4>
                                    <p>Cela nous permettra de s'assurer que les logiciels et les produits livrés sont de haute qualité, fonctionnent comme prévu et répondent aux attentes des utilisateurs finauxt</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-10 order-lg-2 order-1">
                    <div class="process-video bg-img-c" style="background-image: url(assets/img/agile2.jpg);">
                        <div class="video bg-img-c wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="400ms"
                            style="background-image: url(assets/img/agile1.jpg);">
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="working-circle"></div>
    </section>

    <!--====== Testimonials part start ======-->
    <section class="testimonial-section-two section-gap">
        <div class="container">
            <div class="row justify-content-center no-gutters">
                <div class="col-lg-9">
                    <div class="section-title both-border text-center mb-80">
                        <span class="title-tag">Nos Temoingnages & Encouragements</span>
                        <h2 class="title">Ce que nos clients & partenaires disent sur nous</h2>
                    </div>
                    <div class="testimonial-items" id="testimonialSliderTwo">
                        <div class="testimonial-item" data-thumb="assets/img/testimonial/author.png">
                            <div class="author-img">
                                <img src="assets/img/testimonial/author-8.jpg" alt="Image">
                            </div>
                            <div class="content">
                                <p>
                                    <span class="quote-top">
                                        <i class="fas fa-quote-left"></i>
                                    </span>
                                    Chaque étape que vous franchissez témoigne de votre résilience et de votre esprit d'innovation. Le chemin que vous avez choisi est parsemé d'opportunités 
                                    et de défis, mais je suis convaincu que votre équipe est plus que prête à relever ces défis avec succès
                                    <span class="quote-bottom">
                                        <i class="fas fa-quote-right"></i>
                                    </span>
                                </p>
                            </div>
                            <div class="author-name">
                                <h4>Wendmemi . OUEDRAOGO</h4>
                                <span>Consultant à Switch Maker</span>
                            </div>
                        </div>
                        <div class="testimonial-item" data-thumb="assets/img/testimonial/author-3.png">
                            <div class="author-img">
                                <img src="assets/img/testimonial/author-8.jpg" alt="Image">
                            </div>
                            <div class="content">
                                <p>
                                    <span class="quote-top">
                                        <i class="fas fa-quote-left"></i>
                                    </span>
                                    Je suis là pour vous soutenir à chaque étape du processus, et je crois fermement en votre capacité à transformer vos ambitions en réalisations 
                                    tangibles. Avancez avec confiance, et sachez que votre travail acharné portera ses fruits
                                    <span class="quote-bottom">
                                        <i class="fas fa-quote-right"></i>
                                    </span>
                                </p>
                            </div>
                            <div class="author-name">
                                <h4>Fabrice YOUGBARE</h4>
                                <span>Développeur Sénior à Disney</span>
                            </div>
                        </div>
                      
                        <div class="testimonial-item" data-thumb="assets/img/testimonial/author-5.png">
                            <div class="author-img">
                                <img src="assets/img/testimonial/author-8.jpg" alt="Image">
                            </div>
                            <div class="content">
                                <p>
                                    <span class="quote-top">
                                        <i class="fas fa-quote-left"></i>
                                    </span>
                                    Merci pour votre engagement envers l’excellence. C’est un plaisir d’avoir affaire à une entreprise qui se soucie autant de la qualité
                                    <span class="quote-bottom">
                                        <i class="fas fa-quote-right"></i>
                                    </span>
                                </p>
                            </div>
                            <div class="author-name">
                                <h4>Richard E. DERME</h4>
                                <span>Fondateur de quali Approche</span>
                            </div>
                        </div>
                        <div class="testimonial-item" data-thumb="assets/img/testimonial/author-6.png">
                            <div class="author-img">
                                <img src="assets/img/abass.png" alt="Image" width="100" height="99">
                            </div>
                            <div class="content">
                                <p>
                                    <span class="quote-top">
                                        <i class="fas fa-quote-left"></i>
                                    </span>
                                    Merci pour l'excellent travail accompli ! Je n'hésiterai pas à recommander vos services à mon entourage et à revenir vers vous pour mes futurs besoins
                                    <span class="quote-bottom">
                                        <i class="fas fa-quote-right"></i>
                                    </span>
                                </p>
                            </div>
                            <div class="author-name">
                                <h4>Abasse. BAMBORE</h4>
                                <span>Fondateur Global Energie Innternational (GEI)</span>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
     
    </section>
    <!--====== Testimonials part end ======-->

    <!--====== Video Start ======-->


    <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/' class="client-img d-block text-center">
                            <img src="assets/img/logoGEI.png" alt="" height="100">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/' class="client-img d-block text-center">
                            <img src="assets/img/logoGEI.png" alt="" height="100">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/h' class="client-img d-block text-center">
                            <img src="assets/img/logoGEI.png" alt="" height="100">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/' class="client-img d-block text-center">
                            <img src="assets/img/logoGEI.png" alt="" height="100">
                        </a>
                    </div>
                
                   
                    
                </div>
            </div>
        </div>
    </section>
    <div class="contact-form grey-bg ">
        <div class="row no-gutters justify-content-center m-4">
            <div class="col-10">
                <div class="section-title text-center mb-40">
                    <h2 class="title">N'hésitez pas à nous contactez</h2>
                </div>

                <form action="#">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="input-group mb-30">
                                <input type="text" placeholder="Votre nom et prénoms">
                                <span class="icon"><i class="far fa-user-circle"></i></span>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="input-group mb-30">
                                <input type="email" placeholder="Votre adresse email">
                                <span class="icon"><i class="far fa-envelope-open"></i></span>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="input-group mb-30">
                                <input type="text" placeholder="Votre numéro de téléphone">
                                <span class="icon"><i class="far fa-phone"></i></span>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-group textarea mb-30">
                                <textarea placeholder="Ecrire votre message"></textarea>
                                <span class="icon"><i class="far fa-pencil"></i></span>
                            </div>
                        </div>
                        <div class="col-12 text-center">
                            <button type="button" class="main-btn">Envoyer le message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!--====== Client Area End ======-->
    <app-footer2></app-footer2>