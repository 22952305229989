import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutusComponent } from './components/pages/aboutus/aboutus.component';
import { Service2Component } from './components/pages/service2/service2.component';
import { ContactusComponent } from './components/pages/contactus/contactus.component';
import { Homepage2Component } from './components/pages/homepage2/homepage2.component';

const routes: Routes = [

{path: '', component: Homepage2Component},
/* {path: 'homepage2', component: Homepage2Component},
{path: 'homepage3', component: Homepage3Component}, */
{path: 'a_propos', component: AboutusComponent},
{path: 'services', component: Service2Component},
{path: 'contact-nous', component: ContactusComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
