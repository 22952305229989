<app-header2></app-header2>
<!--====== Breadcrumb part Start ======-->
    <section class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/service2.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">Nos services</h1>
                <ul>
                    <li><a routerLink='/'>Acceuil</a></li>
                    <li>Nos services</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section>
    <!--====== Breadcrumb part End ======-->

    <!--====== Service Section Start ======-->
    <section class="service-section section-gap">
        <div class="container">
            <!-- Section Title -->
            <div class="section-title text-center both-border mb-50">
                <span class="title-tag">Nos services</span>
                <h2 class="title">Nous vous  fournissons le plus exclusif
                    des services</h2>
            </div>
            <!-- Services Boxes -->
            <div class="row service-boxes justify-content-center">
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                    <div class="service-box-three">
                        <div class="icon">
                            <img src="assets/img/icons/01.png" alt="Icon">
                        </div>
                        <h3>Développement d'applications mobile</h3>
                        <p>(ANDROID,IOS)</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="500ms">
                    <div class="service-box-three">
                        <div class="icon">
                            <img src="assets/img/icons/02.png" alt="Icon">
                        </div>
                        <h3>Développement d'applications web (site web )</h3>
                        <p>Entreprise ,d'écoles ...</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                    <div class="service-box-three">
                        <div class="icon">
                            <img src="assets/img/icons/03.png" alt="Icon">
                        </div>
                        <h3>Administrations réseaux (LAN ,WAN)</h3>
                        <p>Cablages  ,installations des systèmes exploitations </p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="700ms">
                    <div class="service-box-three">
                        <div class="icon">
                            <img src="assets/img/icons/04.png" alt="Icon">
                        </div>
                        <h3>Confections des afffiches publicitairess</h3>
                        <p>Quis autem velrepres hend</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
  <hr>
    <section class="wcu-section section-gap">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <div class="wcu-video wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                        <div class="video-poster-one bg-img-c"
                            style="background-image: url(assets/img/part2.jpg);">
                        </div>
                        <div class="video-poster-two bg-img-c"
                            style="background-image: url(assets/img/part1.jpg);">
                          
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-10">
                    <div class="wcu-text-two">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag">Pourquoi nous confier vos projet ?</span>
                            <h2 class="title">Nous somme votre partenaire idéal</h2>
                        </div>
                        <p>
                            Chez KOUBANCA Digital, nous nous engageons à offrir des solutions innovantes et personnalisées pour vos projets. Avec une équipe d'au moins 04 ans d'expérience dans le domaine de la programmation d'applications et des réseaux télécoms,
                            nous sommes votre partenaire idéal pour transformer vos idées en réussites concrètes.Les mots suivants  nous deffinissent :
                        </p>
                        <ul class="wcu-list clearfix">
                            <li>
                                <i class="far fa-check-circle"></i> Cout très moyenne
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Efficacité
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Analyse et Écoute
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Solutions Sur Mesure
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Flexibilité et Adaptabilité
                            </li>
                            
                            <li>
                                <i class="far fa-check-circle"></i> Proactivité
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Communication
                            </li>
                            
                            <li>
                                <i class="far fa-check-circle"></i> Rapidité
                            </li>
                        </ul>
                        <a routerLink='/contact-us' class="main-btn">Nous parler de votre projet</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--====== Client Area End ======-->
    <app-footer2></app-footer2>